import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

//internals
import AddCareGiver from './views/AddCareGiver';
import AddCompany from "./views/AddCompany";
import AddDevice from './views/AddDevice/index1';
import AddDeviceProfile from "./views/AddDeviceProfile";
import AddPatient from './views/AddPatient';
import AddPhysician from './views/AddPhysician';
import AddSensor from './views/AddSensor';
import Dashboard from './views/Dashboard';
import EditCompany from "./views/EditCompany";
import EditDevice from "./views/EditDevice";
import EditDeviceProfile from "./views/EditDeviceProfile";
import EditPatient from './views/EditPatient';
import EditPhysician from './views/EditPhysician';
import EditSensor from './views/EditSensor';
import LogHistory from './views/LogHistory';
import ManageBed from './views/ManageBed';
import ManageCareGiver from './views/ManageCareGiver';
import ManageCompany from './views/ManageCompany';
import ManageDevice from './views/ManageDevice';
import DeviceHistory from './views/ManageDevice/DeviceHistory';
import ManageDeviceProfile from './views/ManageDeviceProfile';
import ManagePatient from './views/ManagePatient';
import ManagePhysician from './views/ManagePhysician';
import ManageSensor from './views/ManageSensor';
import NotFound from "./views/NotFound";
import PatientView from './views/PatientView';
import Profile from './views/Profile';
import ForgotPassword from "./views/ResetPassword";
import SignIn from "./views/SignIn";
import SignUp from "./views/SignUp";
import WizardEdit from "./views/WizardEdit";
import WizardSetup from "./views/wizardSetup";

//externals
import secureLocalStorage from 'react-secure-storage';
import ManageDiagnostics from './views/ManageDiagnostics';

// Assuming you have a function to retrieve the user's role
// You can replace it with your actual implementation
const getUserRole = () => {
    const userRoleId = secureLocalStorage.getItem("roleId"); // Fetch user role ID
    let userRole;
    switch (userRoleId) {
        case '3':
            userRole = 'Tenant';
            break;
        case '4':
            userRole = 'Company';
            break;
        case '6':
            userRole = 'Physician';
            break;
        case '8':
            userRole = 'Patient';
            break;
        default:
            userRole = null; // Handle unknown roles if necessary
            break;
    }
    return userRole;
};

const PrivateRoute = ({ component: Component, requiredRoles, ...rest }) => (
    <Route {...rest} render={(props) => (
        requiredRoles.includes(getUserRole())
            ? <Component {...props} />
            : <Redirect to="*" />
    )} />
);

// Define your route configuration with requiredRoles
const routes = [
    { path: '/dashboard', component: Dashboard, requiredRoles: ['Tenant', 'Company', 'Physician'] },
    { path: '/profile', component: Profile, requiredRoles: ['Tenant', 'Company', 'Physician', 'Patient'] },
    { path: '/managecustomer', component: ManageCompany, requiredRoles: ['Tenant'] },
    { path: '/addcustomer', component: AddCompany, requiredRoles: ['Tenant'] },
    { path: '/editcompany', component: EditCompany, requiredRoles: ['Tenant'] },
    { path: '/wizardsetup', component: WizardSetup, requiredRoles: ['Tenant'] },
    { path: '/wizardedit', component: WizardEdit, requiredRoles: ['Tenant'] },
    { path: '/deviceprofile', component: ManageDeviceProfile, requiredRoles: ['Tenant'] },
    { path: '/adddeviceprofile', component: AddDeviceProfile, requiredRoles: ['Tenant'] },
    { path: '/editdeviceprofile', component: EditDeviceProfile, requiredRoles: ['Tenant'] },
    { path: '/managesensor', component: ManageSensor, requiredRoles: ['Tenant'] },
    { path: '/registersensor', component: AddSensor, requiredRoles: ['Tenant'] },
    { path: '/editsensor', component: EditSensor, requiredRoles: ['Tenant'] },
    { path: '/loghistory', component: LogHistory, requiredRoles: ['Tenant'] },
    { path: '/managedevice', component: ManageDevice, requiredRoles: ['Tenant', 'Company'] },
    { path: '/registerdevice', component: AddDevice, requiredRoles: ['Tenant', 'Company'] },
    { path: '/editdevice', component: EditDevice, requiredRoles: ['Tenant', 'Company'] },
    { path: '/devicehistory', component: DeviceHistory, requiredRoles: ['Tenant', 'Company', 'Physician'] },
    { path: '/managetherapist', component: ManagePhysician, requiredRoles: ['Company'] },
    { path: '/editphysician', component: EditPhysician, requiredRoles: ['Company'] },
    { path: '/registertherapist', component: AddPhysician, requiredRoles: ['Company'] },
    { path: '/managecaregiver', component: ManageCareGiver, requiredRoles: ['Company'] },
    { path: '/registercaregiver', component: AddCareGiver, requiredRoles: ['Company'] },
    { path: '/registerpatient', component: AddPatient, requiredRoles: ['Company'] },
    { path: '/managebeds', component: ManageBed, requiredRoles: ['Company'] },

    { path: '/managepatient', component: ManagePatient, requiredRoles: ['Company', 'Physician'] },
    { path: '/editpatient', component: EditPatient, requiredRoles: ['Company', 'Physician'] },
    { path: '/patientview', component: PatientView, requiredRoles: ['Company', 'Physician'] },
    { path: '/managepatient', component: ManagePatient, requiredRoles: ['Company', 'Physician'] },
    { path: '/managepatient', component: ManagePatient, requiredRoles: ['Company', 'Physician'] },
    // { path: '/managediagnostics', component: ManageDiagnostics, requiredRoles: ['Company','Physician'] },

    // Add more routes here with their required roles
];

const Routers = () => {
    return (
        <Switch>
            <Redirect exact from="/" to="/SignIn" />
            {routes.map((route, index) => (
                <PrivateRoute
                    key={index}
                    path={route.path}
                    component={route.component}
                    requiredRoles={route.requiredRoles}
                />
            ))}

            <Route path="/SignIn" component={SignIn} />
            <Route path="/SignUp" component={SignUp} />
            <Route path="/forgotPassword" component={ForgotPassword} />
            {/* Add more public routes here */}
            <Route path="*" component={NotFound} />
        </Switch>
    );
};

export default Routers;
