// store.js

import { createStore } from 'redux';

// Define initial state
const initialState = {
  userData: null,
};

// Define action types
const SET_USER_DATA = 'SET_USER_DATA';

// Define actions
export const setUserData = (userData) => ({
  type: SET_USER_DATA,
  payload: userData,
});

// Define reducer
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

// Create Redux store
const store = createStore(reducer);

export default store;