import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import "./style.css";

// @mui-components
import LoadingButton from "@mui/lab/LoadingButton";
import {
    Avatar,
    Box,
    Container,
    CssBaseline,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

//components
import Iconify from "../../components/iconify";
import Logo from "../../components/logo";

//internals
import LoginImg from "../../assets/illustrations/medicine.svg";

// hooks
import useResponsive from "../../hooks/useResponsive";

//services
import ApiCalls from "../../services/try";

//externals
import { Icon } from "@iconify/react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "react-toastify/dist/ReactToastify.css";
import swal from "sweetalert";

// ----------------------------------------------------------------------

const StyledContent = styled("div")(({ theme }) => ({
    maxWidth: 480,
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const SignUp = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [img, setImg] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [gender, setGender] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
    const [confirmPasswordErrorMessage, setConfirmPasswordErrorMessage] =
        useState("");
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
    const [phoneErrorMessage, setPhoneErrorMessage] = useState("");

    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [redirectToReferrer, setRedirectToReferrer] = useState(false);
    const [hover, setHover] = useState(false);

    const onChangeX = (e) => {
        const files = e.target.files;
        const file = files[0];
        getBase64(file);
    };
    const onLoad = (fileString) => {
        setImg(fileString);
        console.log("img:" + img);
    };
    const getBase64 = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            setImg(e.target.result);
            onLoad(reader.result);
        };
    };

    const handleBlur = (event) => {
        if (!event.target.value) {
            switch (event.target.name) {
                case "firstName":
                    setFirstNameError(true);
                    break;
                case "lastName":
                    setLastNameError(true);
                    break;
                case "email":
                    setEmailError(true);
                    break;
                case "phone":
                    setPhoneError(true);
                    break;
                case "password":
                    setPasswordError(true);
                    setPasswordErrorMessage("Password is Required");
                    break;
                case "confirmPassword":
                    setConfirmPasswordError(true);
                    setConfirmPasswordErrorMessage("Password is Required");
                    break;
                default:
                    break;
            }
        }
    };

    const handleChange = (event) => {
        switch (event.target.name) {
            case "firstName":
                setFirstNameError(false);
                setFirstName(event.target.value);
                break;
            case "lastName":
                setLastNameError(false);
                setLastName(event.target.value);
                break;
            case "email":
                setEmailError(false);
                setEmail(event.target.value);
                break;
            case "phone":
                setPhoneError(false);
                setPhone(event.target.value);
                break;
            case "password":
                setPasswordError(false);
                setPassword(event.target.value);
                break;
            case "confirmPassword":
                setConfirmPasswordError(false);
                if (event.target.value !== password) {
                    setConfirmPasswordError(true);
                    setConfirmPasswordErrorMessage("Password must match");
                }
                setConfirmPassword(event.target.value);
                break;
            default:
                break;
        }
    };

    const handleClick = () => {
        const emailRegex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const phoneRegex = /^[0-9]{10}$/;

        // Collect validation errors
        const errors = {};

        if (!firstName) {
            errors.firstNameError = true;
        }
        if (!lastName) {
            errors.lastNameError = true;
        }
        if (!email) {
            errors.emailError = true;
            errors.emailErrorMessage = "Email address is required";
        } else if (!emailRegex.test(email)) {
            errors.emailError = true;
            errors.emailErrorMessage = "Invalid Email Address";
        }
        if (!phone) {
            errors.phoneError = true;
            errors.phoneErrorMessage = "Phone Number is required";
        } else if (!phoneRegex.test(phone)) {
            errors.phoneError = true;
            errors.phoneErrorMessage = "Invalid Phone Number";
        }
        if (!password) {
            errors.passwordError = true;
            errors.passwordErrorMessage = "Password is required";
        }
        // else {
        //     const passwordValidationError = PasswordValidation.validatePassword(password);
        //     if (passwordValidationError) {
        //         errors.passwordError = true;
        //         errors.passwordErrorMessage = passwordValidationError;
        //     }
        // }
        if (!confirmPassword) {
            errors.confirmPasswordError = true;
            errors.confirmPasswordErrorMessage = "Confirm Password is required";
        } else if (password !== confirmPassword) {
            errors.confirmPasswordError = true;
            errors.confirmPasswordErrorMessage = "Passwords must match";
        }

        // Display errors if any
        if (Object.keys(errors).length > 0) {
            // Set all errors at once
            setFirstNameError(errors.firstNameError || false);
            setLastNameError(errors.lastNameError || false);
            setEmailError(errors.emailError || false);
            setEmailErrorMessage(errors.emailErrorMessage || "");
            setPhoneError(errors.phoneError || false);
            setPhoneErrorMessage(errors.phoneErrorMessage || "");
            setPasswordError(errors.passwordError || false);
            setPasswordErrorMessage(errors.passwordErrorMessage || "");
            setConfirmPasswordError(errors.confirmPasswordError || false);
            setConfirmPasswordErrorMessage(errors.confirmPasswordErrorMessage || "");

            return; // Stop further execution
        } else {
            var s = {
                firstName: firstName,
                lastName: lastName,
                userName: email,
                phone: phone,
                password: password,
                newPassword: confirmPassword,
                gender: gender === null || "" ? "" : gender,
                img: img === null || "" ? "null" : img,
            };
            ApiCalls.registerwithoutJWT("tenantregister", s)
                .then(function (response) {
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal("Tenant Registered Succesfully", {
                            icon: "success",
                        });
                        setRedirectToReferrer(true);
                    } else if (
                        response.data.status.message === "Error"
                    ) {
                        setPasswordError(true);
                        setPasswordErrorMessage(response.data.status.details);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                    if (error.message === "Request failed with status code 409") {
                        swal("Email Already Registered", {
                            icon: "warning",
                        });
                    } else if (error.message === "Network Error") {
                        swal("Network Error", {
                            icon: "error",
                        });
                    }
                });
            // For example, to navigate to another page using React Router:
        }
    };

    const mdUp = useResponsive("up", "md");

    if (redirectToReferrer) {
        return <Redirect to={"/"} />;
    }
    return (
        <div class="container">
            <div class="forms-container">
                <div class="signin-signup">
                    <Grid container component="main" sx={{ height: "100vh" }}>
                        <CssBaseline />
                        <Box
                            sx={{
                                my: 8,
                                mx: 4,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                            }}
                        >
                            <Logo
                                sx={{
                                    position: "fixed",
                                    top: { xs: 16, sm: 24, md: 50 },
                                    right: { xs: 16, sm: 24, md: 60 },
                                }}
                            />
                        </Box>

                        <Container maxWidth="sm">
                            <StyledContent>
                                {/* <Registration Form /> */}

                                <Stack
                                    spacing={2}
                                    sx={{
                                        mb: 3,
                                        mx: "auto",
                                    }}
                                >
                                    <Avatar
                                        alt="Image"
                                        variant="circular"
                                        src={img}
                                        sx={{
                                            marginLeft: 5,
                                            width: 150,
                                            height: 150,
                                            transition: "all 0.2s ease-in-out",
                                            "&:hover": {
                                                cursor: "pointer",
                                                transform: "scale(1.1)",
                                            },
                                        }}
                                        onMouseEnter={() => setHover(true)}
                                        onMouseLeave={() => setHover(false)}
                                        onClick={() =>
                                            document.getElementById("avatar-input").click()
                                        }
                                    >
                                        <div
                                            className="icon-container"
                                            onMouseEnter={() => setHover(true)}
                                            onMouseLeave={() => setHover(false)}
                                        >
                                            {hover ? (
                                                <Icon
                                                    icon="line-md:upload-loop"
                                                    color="#4481eb"
                                                    width="80"
                                                />
                                            ) : (
                                                <Icon
                                                    icon="ooui:user-avatar-outline"
                                                    color="#4481eb"
                                                    width="80"
                                                />
                                            )}
                                            <div
                                                className="text-container"
                                                style={{ textAlign: "center" }}
                                            >
                                                <Typography
                                                    variant="subtitle2"
                                                    display="block"
                                                    color="primary"
                                                    gutterBottom
                                                >
                                                    {hover ? "Upload" : ""}
                                                </Typography>
                                            </div>
                                        </div>
                                    </Avatar>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        id="avatar-input"
                                        onChange={onChangeX}
                                        style={{ display: "none" }}
                                    />
                                </Stack>

                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    sx={{ my: 2 }}
                                >
                                    <TextField
                                        name="firstName"
                                        label="First Name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                        error={firstNameError}
                                        helperText={firstNameError && "First Name is required"}
                                        autoComplete="new-password"
                                    />
                                    <TextField
                                        name="lastName"
                                        label="Last Name"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        fullWidth
                                        error={lastNameError}
                                        helperText={lastNameError && "Last Name is required"}
                                        autoComplete="new-password"
                                    />
                                </Stack>

                                <Stack spacing={2}>
                                    <TextField
                                        name="email"
                                        label="Email address"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={emailError}
                                        helperText={emailError && emailErrorMessage}
                                        autoComplete="new-password"
                                    />
                                </Stack>

                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    sx={{ my: 2 }}
                                >
                                    <TextField
                                        fullWidth
                                        name="phone"
                                        type="number"
                                        label="Phone"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={phoneError}
                                        helperText={phoneError && phoneErrorMessage}
                                        autoComplete="new-password"
                                    />
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">
                                            Gender
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={gender}
                                            label="Gender"
                                            onChange={(e) => setGender(e.target.value)}
                                        >
                                            <MenuItem value="Female">Female</MenuItem>
                                            <MenuItem value="Male">Male</MenuItem>
                                            <MenuItem value="Others">Others</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Stack>

                                <Stack
                                    direction="column"
                                    spacing={2}
                                    justifyContent="space-between"
                                    sx={{ my: 2 }}
                                >
                                    <TextField
                                        name="password"
                                        label="Password"
                                        type={showPassword ? "text" : "password"}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={passwordError}
                                        helperText={passwordError && passwordErrorMessage}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() => setShowPassword(!showPassword)}
                                                        onMouseDown={(e) => e.preventDefault()}
                                                        edge="end"
                                                    >
                                                        <Iconify
                                                            icon={
                                                                showPassword
                                                                    ? "eva:eye-fill"
                                                                    : "eva:eye-off-fill"
                                                            }
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        autoComplete="new-password"
                                    />
                                    <TextField
                                        name="confirmPassword"
                                        label="Confirm Password"
                                        type={showConfirmPassword ? "text" : "password"}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        error={confirmPasswordError}
                                        helperText={
                                            confirmPasswordError && confirmPasswordErrorMessage
                                        }
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() =>
                                                            setShowConfirmPassword(!showConfirmPassword)
                                                        }
                                                        onMouseDown={(e) => e.preventDefault()}
                                                        edge="end"
                                                    >
                                                        <Iconify
                                                            icon={
                                                                showConfirmPassword
                                                                    ? "eva:eye-fill"
                                                                    : "eva:eye-off-fill"
                                                            }
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        autoComplete="new-password"
                                    />
                                </Stack>

                                <LoadingButton
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    onClick={handleClick}
                                >
                                    Create Account
                                </LoadingButton>
                            </StyledContent>
                        </Container>
                    </Grid>
                </div>
            </div>

            <div class="panels-container">
                <div class="panel left-panel">
                    <div class="content">
                        <h3>Get Started with IoMT</h3>
                        <p>Already have an account?</p>
                        <Link to="/">
                            <button class="btn transparent" id="sign-up-btn">
                                Sign In
                            </button>
                        </Link>
                    </div>
                    <img src={LoginImg} class="image" alt="" />
                </div>
            </div>
        </div>
    );
};

export default SignUp;
