import { Upload } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  InputBase,
  Paper
} from "@mui/material";
import React, { useEffect, useState } from "react";
import secureLocalStorage from "react-secure-storage";
import swal from "sweetalert";
import ApiCalls from "../../services/try";

const Mastermenu = ({ steps, activeStep, setActiveStep }) => {
  const [menu, setMenu] = useState([]);
  const [file, setFile] = useState([]);
  const [menuCheck, setMenuCheck] = useState([]);
  const [userId, setUserId] = useState("");
  const [focus, setFocus] = useState(true);
  const [base, setBase] = useState(true);
  const [doneEditing, setDoneEditing] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState([]);

  useEffect(() => {
    console.log("Getting master menu list...");
    let user = secureLocalStorage.getItem("userid");
    user = user.replace(/['"]+/g, "");

    setUserId(user);

    ApiCalls.getMasterMenuList(user).then((response) => {
      let list = response.data;
      console.log("Getting custom menu list...");

      ApiCalls.getCustomMenuList(user).then((response) => {
        setSelectedMenu(response.data);

        if (response.data.length > 0) {
          list.forEach(item => {
            const matchedMenu = response.data.find(menu => menu.menuuid === item.menuuuid);
            if (matchedMenu) {
              item.menuText = matchedMenu.menuText;
              item.checked = "true";
            }
          });
          setMenu(list);

          const updatedMenuCheck = response.data.map(menu => ({
            userId: user,
            menuuuid: menu.menuuuid,
            menuText: menu.menuText,
            priority: menu.priority,
            file: file,
          }));

          setMenuCheck(updatedMenuCheck);
        } else {
          setMenu(list);
        }
      });
    });
  }, []);

  const editMenu = () => {
    setFocus(false);
    setBase(false);
    alert("Tap the text to edit");
    setDoneEditing(true);
  };

  const doneEdit = () => {
    setFocus(true);
    setBase(true);
    setDoneEditing(false);
  };

  const handleCheckboxChange = (e, text, priority) => {
    const st = {
      userId: userId,
      menuuuid: e.target.name,
      menuText: text,
      priority: priority,
    };

    if (e.target.checked) {
      setMenuCheck(prevMenuCheck => [...prevMenuCheck, st]);
    } else {
      swal({
        title: "Are you sure?",
        text: `You want to remove ${e.target.value} from Menu!`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          setMenuCheck(prevMenuCheck => prevMenuCheck.filter(menu => menu.menuuuid !== e.target.name));
        }
      });
    }
  };

  const handleMenucheck = () => { };

  const forwardSubmit = () => {
    console.log("Going to submit...");
    console.log(JSON.stringify(menuCheck));

    let user = secureLocalStorage.getItem("userid");
    user = user.replace(/['"]+/g, '');

    for (let i = 0; i < file.length; i++) {
      const obj1 = file[i];
      if (obj1.hasOwnProperty('menuText')) {
        const menuicon = obj1.menuicon;
        for (let j = 0; j < menuCheck.length; j++) {
          const obj2 = menuCheck[j];
          if (obj2.menuText === obj1.menuText) {
            obj2.menuicon = menuicon;
          }
        }
      }
    }

    const key = 'menuicon';
    menuCheck.forEach(item => {
      if (!item.hasOwnProperty(key)) {
        item[key] = "";
      }
    });

    ApiCalls.register("ithings/insertCustomMenu", menuCheck)
      .then((response) => {
        console.log("Response of API call:", response.data);
        if (response.data[0].status.message === "Success") {
          setActiveStep(2);
        }
      });
  };

  const fileInput = (e, menuText) => {
    if (e.target.files.length === 0) {
      setFile(prevFile => prevFile.filter(file => file.menuText !== menuText));
    } else {
      const fileExtension = e.target.files[0].name.split(".").at(-1);
      const allowedFileTypes = ["png"];
      if (!allowedFileTypes.includes(fileExtension)) {
        window.alert(`File does not support. File type must be ${allowedFileTypes.join(", ")}`);
        return false;
      } else if (e.target.files[0].size > 300000) {
        window.alert("Please upload a file smaller than 300kb");
        return false;
      } else {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        ApiCalls.storeFile("uploadCustomMenuandIcon", formData)
          .then((response) => {
            if (response.data.message === "File Uploaded Successfully") {
              setFile(prevFile => [
                ...prevFile,
                {
                  menuText: menuText,
                  menuicon: e.target.files[0].name,
                }
              ]);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  };

  return (
    <div style={{ padding: '20px', width: 600 }}>
      {base ? (
        <Button
          sx={{
            fontFamily: "Lato, sans-serif",
            backgroundColor: "#51C4D3",
            alignItems: "center",
            marginBottom: '20px',
          }}
          onClick={editMenu}
          variant="contained"
        >
          Change Menu Name
        </Button>
      ) : (
        <Button
          sx={{
            fontFamily: "Lato, sans-serif",
            backgroundColor: "#51C4D3",
            alignItems: "center",
          }}
          onClick={doneEdit}
          variant="contained"
        >
          Done Editing
        </Button>
      )}

      <Grid container spacing={2} justifyContent="center">
        {menu.map((menutext, index) => (
          <Grid item xs={12} sm={6} md={6} lg={6} key={index}>
            <Paper
              sx={{
                backgroundColor: "#F4F6F8",
                boxShadow: 5,
                mt: 2,
                height: "auto",
                width: "100%", // Full width
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                py: 2,
                px: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: "column",
                  justifyContent: 'space-between',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      value={menutext.menuText}
                      name={menutext.menuuuid}
                      onChange={(e) =>
                        handleCheckboxChange(e, menutext.menuText, menutext.priority)
                      }
                      defaultChecked={menutext.checked}
                    />
                  }
                  label={
                    <InputBase
                      readOnly={focus}
                      name={menutext.menuuuid}
                      sx={
                        base
                          ? { color: "#090A0A", width: "200px" }
                          : {
                            color: "#1976D2",
                            borderColor: "blue",
                            border: "2px solid #38A3A5",
                            borderRadius: "5px",
                            width: "200px",
                            height: "50px",
                            px: 2,
                          }
                      }
                      autoFocus={base}
                      defaultValue={menutext.menuText}
                      onChange={(e) => {
                        menutext.menuText = e.target.value;
                        handleMenucheck(e, menutext.menuText);
                      }}
                    />
                  }
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', mt: 1 }}>
                  <input
                    accept="image/*"
                    type="file"
                    onChange={(e) => fileInput(e, menutext.menuText)}
                    style={{ display: 'none' }} // Hide the input element
                    id={`upload-button-${index}`} // Unique ID for each upload button
                  />
                  <label htmlFor={`upload-button-${index}`}>
                    <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
                      <Button
                        variant="outlined"
                        component="span"
                        size="small"
                        startIcon={<Upload />}
                      >
                        Upload
                      </Button>
                      <span style={{ marginLeft: "10px", fontSize: "13px" }}>
                        (*optional)
                      </span>
                    </Box>
                  </label>
                </Box>
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Container
        style={{
          display: 'flex',
          marginTop: "20px",
          justifyContent: "space-evenly",
        }}
      >
        <Button
          sx={{ marginLeft: '30%' }}
          variant="contained"
          color="primary"
          onClick={() => setActiveStep(0)}
        >
          Prev
        </Button>
        <Button
          disabled={doneEditing}
          sx={{ marginLeft: '20px' }}
          variant="contained"
          color="primary"
          onClick={forwardSubmit}
        >
          Next
        </Button>
      </Container>
    </div>
  );
};

export default Mastermenu;
