import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// Components
import { Container } from '@mui/material';
import Preview from '../../layout/Preview';

// MUI Components
import {
    Box,
    Breadcrumbs,
    Button,
    Chip,
    CircularProgress,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@mui/material';
import { Home } from '@mui/icons-material';
import { emphasize, styled } from '@mui/material/styles';

// Services
import ApiCalls from '../../services/try';

// Externals
import 'react-confirm-alert/src/react-confirm-alert.css';
import secureLocalStorage from 'react-secure-storage';

//-------------------------------------------------------------------------------

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
    };
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

//-------------------------------------------------------------------------------

const LogHistory = () => {
    const [logs, setLogs] = useState([]);
    const [filteredLogs, setFilteredLogs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [filterEmail, setFilterEmail] = useState('');
    const [filterDate, setFilterDate] = useState('');

    const parseLog = (logString) => {
        const logParts = logString.split(', ').map(part => part.split(': ')[1]);
        const dateTime = new Date(logParts[1] + ' GMT');

        // Convert GMT time in the log message to local time
        let logMessage = logParts[3];
        const gmtTimeMatch = logMessage.match(/\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/);
        if (gmtTimeMatch) {
            const gmtTime = new Date(gmtTimeMatch[0] + ' GMT');
            const localTime = gmtTime.toLocaleString();
            logMessage = logMessage.replace(gmtTimeMatch[0], localTime);
        }

        return {
            username: logParts[0],
            datetime: dateTime,
            logmessage: logMessage
        };
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
    };

    const fetchLogs = () => {
        setLoading(true);
        const tenantId = secureLocalStorage.getItem('tenantId'); // Assuming you store tenantId securely
        ApiCalls.getdetails(`displayLogs?tenantId=${tenantId}`)
            .then((response) => {
                if (response.data && Array.isArray(response.data)) {
                    const parsedLogs = response.data.map(parseLog);
                    const sortedLogs = parsedLogs.sort((a, b) => b.datetime - a.datetime);
                    setLogs(sortedLogs); // Assuming backend returns an array of log objects
                    setFilteredLogs(sortedLogs);
                } else {
                    setError('Error fetching logs. Invalid data received.');
                }
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                setError('Error fetching logs. Please try again.');
                console.error(error);
            });
    };

    const filterLogs = () => {
        const filtered = logs.filter(log => {
            const matchesEmail = filterEmail ? log.username.includes(filterEmail) : true;
            const logDate = log.datetime ? new Date(log.datetime).toISOString().split('T')[0] : '';
            const matchesDate = filterDate ? logDate === filterDate : true;
            return matchesEmail && matchesDate;
        });
        setFilteredLogs(filtered);
    };

    useEffect(() => {
        fetchLogs();
    }, []);

    useEffect(() => {
        filterLogs();
    }, [filterEmail, filterDate, logs]);

    return (
        <div style={{ display: 'flex' }}>
            <Preview />
            <Container sx={{ marginTop: 10 }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link to="/dashboard">
                            <Button variant="text" startIcon={<Home fontSize="small" />} onMouseDown={handleMouseDown} sx={{ mr: 1 }}>
                                Home
                            </Button>
                        </Link>

                        <StyledBreadcrumb label="Logs History" />
                    </Breadcrumbs>

                </Stack>

                <Stack direction="row" spacing={2} mb={5}>
                    <TextField
                        label="Filter by Email"
                        variant="outlined"
                        value={filterEmail}
                        onChange={(e) => setFilterEmail(e.target.value)}
                    />
                    <TextField
                        label="Filter by Date"
                        variant="outlined"
                        type="date"
                        value={filterDate}
                        onChange={(e) => setFilterDate(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Stack>

                {loading ? (
                    <CircularProgress />
                ) : error ? (
                    <Typography>Error: {error}</Typography>
                ) : filteredLogs.length > 0 ? (
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow sx={{ backgroundColor: 'blue' }}>
                                    <TableCell>Username</TableCell>
                                    <TableCell>LogMessage</TableCell> {/* Changed from 'Module' to 'LogMessage' */}
                                    <TableCell>Datetime</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredLogs.map((log, index) => (
                                    <TableRow key={index} sx={{ backgroundColor: 'lightgray' }}>
                                        <TableCell>{log.username}</TableCell>
                                        <TableCell>{log.logmessage}</TableCell> {/* Changed from 'module' to 'logmessage' */}
                                        <TableCell>
                                            <div>{log.datetime.toLocaleDateString()}</div>
                                            <div>{log.datetime.toLocaleTimeString()}</div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Typography>No logs found</Typography>
                )}
            </Container>
        </div>
    );
};

export default LogHistory;
