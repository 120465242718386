import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

//mui-components
import { DevicesTwoTone, DnsRounded } from '@mui/icons-material';
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Collapse,
    Container,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from '@mui/material';

//components
import Iconify from '../../components/iconify/Iconify';

//services
import ApiCalls from "../../services/try";

//externals
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import secureLocalStorage from "react-secure-storage";
import swal from "sweetalert";

const DeviceProfileReview = ({ handleBack, data }) => {

    const [groups, setGroups] = useState([]);
    const [paramgroup, setParamGroup] = useState([]);
    const [detailsShown, setDetailShown] = useState([]);
    const [detailsShowns, setDetailShowns] = useState([]);
    const [redirect, setRedirect] = useState(false);

    const fetchGroupDetails = () => {
        var deviceUUid = secureLocalStorage.getItem("devProfileIdtoEdit")
        ApiCalls.getdetails("getdevicemodelsbyid/" + deviceUUid)
            .then((response) => {
                console.log(response.data);
                if (response.data.deviceMode != undefined) {
                    const outData = response.data.deviceMode[0];
                    setGroups(outData.parameter);
                } else {
                    setGroups([]);
                }
            }).catch((err) => {
                console.log(err);
                setGroups([])
            })
        // .then(function (response) {
        //     const outData = response.data.deviceMode[0];
        //     console.log(outData);
        //     console.log(outData.parameter);
        //     setGroups(outData.parameter);
        //     // console.log(outData.parameter[0].parameters);
        //     // setSelectedParams(outData.parameter[0].parameters);
        // })
    }

    useEffect(() => {
        fetchGroupDetails();
    }, [])

    const fetchNewGroupDetails = () => {
        var tenantId = secureLocalStorage.getItem("tenantId");
        var companyId = secureLocalStorage.getItem("compId");

        console.log("-----" + tenantId + "-------" + companyId + "------");

        ApiCalls.getdetails("tenant/" + tenantId + "/company/0/physician/0/parametergroup")
            .then(result => {
                console.log(result.data);
                if (result.data.parametergroup != undefined) {
                    const values = result.data.parametergroup;
                    setParamGroup(values);
                } else {
                    setParamGroup([]);
                }
            }).catch((err) => {
                console.log(err);
                setParamGroup([]);
            })
    }

    useEffect(() => {
        fetchNewGroupDetails();
    }, [])

    const toggleShown = groupName => {
        const shownState = detailsShown.slice();
        const index = shownState.indexOf(groupName);
        if (index >= 0) {
            shownState.splice(index, 1);
            setDetailShown(shownState);
        } else {
            shownState.push(groupName);
            setDetailShown(shownState);
        }
    }
    const toggleShowns = username => {
        const shownState = detailsShowns.slice();
        const index = shownState.indexOf(username);
        if (index >= 0) {
            shownState.splice(index, 1);
            setDetailShowns(shownState);
        } else {
            shownState.push(username);
            setDetailShowns(shownState);
        }
    };

    const handleSubmit = () => {

        if (groups) {
            const profileForm = {
                modelNumber: data.modelNumber,
                modelName: data.modelName,
                modelmanufacturer: data.modelmanufacturer,
                modelTags: data.modelTags === "" ? "#" + data.modelName : data.modelTags,
                versionNumber: data.versionNumber,
                devImage: data.devImage === "" ? "null" : data.devImage,
                qrEnabled: data.qrEnabled == true ? "active" : "inactive",
                sensorEnabled: data.sensorEnabled == true ? "active" : "inactive"
            }

            var modelId = secureLocalStorage.getItem("devProfileIdtoEdit")
            ApiCalls.edit("updateDM/" + modelId, profileForm)
                .then(function (response) {
                    console.log("Editing Model Details.....")
                    console.log(response.data);
                    if (response.data.status.message === "Success") {
                        swal("Device Profile Updated Successfully", {
                            icon: "success",
                        });
                        setRedirect(true);
                    }
                }).catch((err) => {
                    console.log(err);
                })
        } else {
            var deviceUUid = secureLocalStorage.getItem("devProfileIdtoEdit")
            mapGrouptoModel(deviceUUid);
        }


    }

    const mapGrouptoModel = (deviceId) => {
        var groupData = {
            groupName: "",
            type: "",
            tags: "",
            parameters: [
                {
                    paramId: "",
                    name: ""
                }

            ]
        }
        var tenantId = secureLocalStorage.getItem("tenantId");
        var companyId = secureLocalStorage.getItem("companyId");
        ApiCalls.register("tenant/" + tenantId + "/company/" + companyId + "/parametergroup/" + deviceId, groupData)
            .then((response) => {
                console.log("Mapping Group Details.....");
                console.log(response.data);

                if (response.data.status.message === "Success") {
                    removeAddedGroup(tenantId, companyId);
                    if (secureLocalStorage.getItem("ruleName")) {
                        var ruleName = secureLocalStorage.getItem("ruleName").toString();
                        updateRuleDevId(deviceId, ruleName);
                    }
                } else {
                    swal("Error adding Device Profile", {
                        icon: "error",
                    });
                }
            })
    }

    const updateRuleDevId = (deviceId, ruleName) => {
        console.log("inside this...");
        console.log(deviceId);
        console.log(ruleName);
        ApiCalls.edit("model/" + deviceId + "/rule/" + ruleName + "/updateruledevId")
            .then((response) => {
                console.log(response.data)
            })
    }


    const removeAddedGroup = (tenantId, companyId) => {
        console.log("TenantId------" + tenantId + "--------CompanyId--------" + companyId);
        ApiCalls.delete("tenant/" + tenantId + "/company/" + companyId + "/parametergroup")
            .then((response) => {
                console.log(response.data);
                if (response.data.message === "Success") {
                    swal("Device Profile Successfully Updated", {
                        icon: "success",
                    });
                    secureLocalStorage.removeItem('currentDeviceID');
                    secureLocalStorage.removeItem('checkedItems');
                    secureLocalStorage.removeItem('currentDeviceName');
                    secureLocalStorage.removeItem('editDeviceId');
                    secureLocalStorage.removeItem('DeviceImage');
                    setRedirect(true);
                } else {
                    swal("Error adding Device Profile", {
                        icon: "error",
                    });
                }
            })
    }


    const handleMouseDown = (event) => {
        event.preventDefault();
    };

    if (redirect) {
        return <Redirect to="/deviceprofile" />;
    }

    return (
        <Container sx={{ marginTop: 5, minWidth: 800 }}>
            <Card>
                <CardHeader />
                <CardContent>
                    <ListItemButton>
                        <ListItemIcon>
                            <DevicesTwoTone />
                        </ListItemIcon>
                        <ListItemText primary="Model Name" />
                        <ListItemText primary={data.modelName} />
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemIcon>
                            <DnsRounded />
                        </ListItemIcon>
                        <ListItemText primary="Model Number" />
                        <ListItemText primary={data.modelNumber} />
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemIcon>
                            <DnsRounded />
                        </ListItemIcon>
                        <ListItemText primary="Version" />
                        <ListItemText primary={data.versionNumber} />
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemIcon>
                            <DnsRounded />
                        </ListItemIcon>
                        <ListItemText primary="Manufacturer" />
                        <ListItemText primary={data.modelmanufacturer} />
                    </ListItemButton>
                    {groups ?
                        groups.map(group => (
                            <div key={group.id}>
                                <ListItemButton onClick={() => toggleShown(group.groupName)}>
                                    <ListItemIcon>
                                        <DnsRounded />
                                    </ListItemIcon>
                                    <ListItemText primary={group.groupName} />
                                    <ListItemIcon>
                                        {detailsShown.includes(group.groupName) ? (
                                            <Iconify icon={'ph:caret-circle-up-fill'} />
                                        ) : (
                                            <Iconify icon={'ph:caret-circle-down-fill'} />
                                        )}
                                    </ListItemIcon>
                                </ListItemButton>
                                <Collapse in={detailsShown.includes(group.groupName)}>
                                    <List component="div" disablePadding>
                                        {group.parameters.map((parameter, index) => (
                                            <ListItemButton key={index} sx={{ pl: 10 }} onClick={() => toggleShowns(parameter.name)}>
                                                <ListItemIcon>
                                                    <Iconify icon={'line-md:check-list-3-filled'} />
                                                </ListItemIcon>
                                                <ListItemText primary={parameter.name} />
                                                <ListItemText primary={parameter.typeName} />
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Collapse>
                            </div>
                        )) : paramgroup.map(group => (
                            <div key={group.id}>
                                <ListItemButton onClick={() => toggleShown(group.groupName)}>
                                    <ListItemIcon>
                                        <DnsRounded />
                                    </ListItemIcon>
                                    <ListItemText primary={group.groupName} />
                                    <ListItemIcon>
                                        {detailsShown.includes(group.groupName) ? (
                                            <Iconify icon={'ph:caret-circle-up-fill'} />
                                        ) : (
                                            <Iconify icon={'ph:caret-circle-down-fill'} />
                                        )}
                                    </ListItemIcon>
                                </ListItemButton>
                                <Collapse in={detailsShown.includes(group.groupName)}>
                                    <List component="div" disablePadding>
                                        {group.parameters.map((parameter, index) => (
                                            <ListItemButton key={index} sx={{ pl: 10 }} onClick={() => toggleShowns(parameter.name)}>
                                                <ListItemIcon>
                                                    <Iconify icon={'line-md:check-list-3-filled'} />
                                                </ListItemIcon>
                                                <ListItemText primary={parameter.name} />
                                                <ListItemText primary={parameter.typeName} />
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Collapse>
                            </div>
                        ))}

                </CardContent>
                <CardActions>
                    <Button
                        fullWidth
                        color="error"
                        size="small"
                        type="submit"
                        variant="outlined"
                        onMouseDown={handleMouseDown}
                        onClick={handleBack}
                        sx={{
                            borderRadius: "12px",
                        }}
                        startIcon={
                            <Iconify icon={'ic:twotone-arrow-left'} />
                        }
                    >
                        Previous
                    </Button>
                    <Button
                        fullWidth
                        color="primary"
                        size="small"
                        type="submit"
                        variant="outlined"
                        onMouseDown={handleMouseDown}
                        onClick={handleSubmit}
                        sx={{
                            borderRadius: "12px",
                        }}
                        endIcon={
                            <Iconify icon={'ion:checkmark-done-circle'} />
                        }
                    >
                        Update
                    </Button>
                </CardActions>
            </Card>
        </Container>
    )
}

export default DeviceProfileReview
