import axios from "axios";
import secureLocalStorage from "react-secure-storage";

const url = "https://stream.iorbit.health:8443/api/v2/";

class ApiCalls {
  // Helper method to get the token from secureLocalStorage
  getToken() {
    return secureLocalStorage.getItem("sessionId");
  }

  login(path, data) {
    return axios.post(url + path, data);
  }

  getRoleList() {
    return axios.get(`${url}ithings/getRolelist`, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }

  getSelectedRoleList(id) {
    return axios.get(`${url}ithings/${id}/getSelectedRolelist`, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }

  insertSelectedRoles(data) {
    return axios.post(`${url}ithings/insertSelectedRoles`, data, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }

  getMasterMenuList(data) {
    return axios.get(`${url}ithings/${data}/getmastermenulist`, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }

  insertRoleBasedMenu(data, user, roleName) {
    return axios.post(
      `${url}ithings/${user}/${roleName}/insertRoleBasedMenu`,
      data,
      { headers: { Authorization: `Bearer ${this.getToken()}` } }
    );
  }

  getRoleBasedMenu(id, roleName) {
    return axios.get(`${url}ithings/${id}/getRoleBasedMenu/${roleName}`, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }

  getCustomMenuList(data) {
    return axios.get(`${url}ithings/${data}/getcustommenulist`, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }

  checkMenuExist(user) {
    return axios.get(`${url}ithings/${user}/checkmenuExist`, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }

  insertselectedmenu_noicon(files) {
    return axios.post(`${url}ithings/insertselectedmenu_noicon`, files, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }

  insertselectedmenu(files) {
    return axios.post(`${url}ithings/insertselectedmenu`, files, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }

  insertCustomMenu(data) {
    return axios.post(`${url}ithings/insertCustomMenu`, data, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }

  insertCustomMenuandIcon(files) {
    return axios.post(`${url}ithings/insertCustomMenuandIcon`, files, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }

  getdetails(path) {
    try {
      return axios.get(url + path, {
        headers: { Authorization: `Bearer ${this.getToken()}` },
      });
    } catch (ex) {
      console.error("Error For retrieving:", ex);
    }
  }

  register(path, data) {
    return axios.post(url + path, data, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }

  post(path, data) {
    return axios.post(url + path, data, {
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
        "Content-Type": "text/plain",
      },
    });
  }

  storeFile(path, data) {
    return axios.post(url + path, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${this.getToken()}`,
      },
    });
  }

  viewFile(path, params) {
    return axios.get(url + path, {
      params, // Pass parameters as query parameters
      headers: {
        Authorization: `Bearer ${this.getToken()}`,
      },
      responseType: "blob", // Expecting a binary data (blob) response
    });
  }

  registerwithoutJWT(path, data) {
    return axios.post(url + path, data);
  }

  registerwithoutAuthorization(path) {
    return axios.post(url + path, { headers: { Authorization: "" } });
  }

  edit(path, data) {
    return axios.put(url + path, data, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }

  userdetails(path) {
    return axios.get(url + path, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }

  delete(path) {
    try {
      return axios.delete(url + path, {
        headers: { Authorization: `Bearer ${this.getToken()}` },
      });
    } catch (e) {
      console.error("APICALL Error:", e);
    }
  }

  getEmbeddedData(path) {
    try {
      return axios.get(url + path, {
        headers: { Authorization: `Bearer ${this.getToken()}` },
      });
    } catch (ex) {
      console.error(ex);
    }
  }

  getCustomFieldList(userId) {
    return axios.get(`${url}ithings/${userId}/getCustomFieldList`, {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    });
  }
}

export default new ApiCalls();
