import React, { useEffect, useRef, useState } from "react";
import { Redirect } from "react-router-dom";

// @mui-components
import {
  ChevronLeftRounded,
  ChevronRightRounded,
  Close,
  InfoRounded,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Dialog,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { Stack } from "@mui/system";

// components
import Iconify from "../../components/iconify";

// sections
import { CountCard, MeasurementList } from "./components";

//services
import ApiCalls from "../../services/try";

//internals
import UserImg from "../../assets/images/avatars/user.png";
import DeviceImg from "../../assets/images/devices.png";
import Preview from "../../layout/Preview";

//externals
import Chart from "chart.js/auto";
import "chartjs-adapter-moment";
import "chartjs-plugin-streaming";
import "chartjs-plugin-zoom";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

// Import and register the line controller
import {
  CategoryScale,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
} from "chart.js";
Chart.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale
);

// ----------------------------------------------------------------------

const MaterialArrow = ({ className, onClick, direction }) => {
  const arrowStyle = {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 1,
    [direction]: direction === "prev" ? "-35px" : "auto",
    right: direction === "next" ? "0" : "10px",
  };

  return (
    <div className={className} onClick={onClick} style={arrowStyle}>
      {direction === "prev" ? (
        <ChevronLeftRounded color="primary" fontSize="large" />
      ) : (
        <ChevronRightRounded color="primary" fontSize="large" />
      )}
    </div>
  );
};

const CHART_HEIGHT = 270;
const LEGEND_HEIGHT = 65;

const StyledCard = styled("div")(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(2),
  "& .apexcharts-canvas svg": { height: CHART_HEIGHT },
  "& .apexcharts-canvas svg,.apexcharts-canvas foreignObject": {
    overflow: "visible",
  },
  "& .apexcharts-legend": {
    height: LEGEND_HEIGHT,
    alignContent: "center",
    position: "relative !important",
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

export default function Dashboard() {
  const [companyCount, setCompanyCount] = useState(0);
  const [physicianCount, setPhysicianCount] = useState(0);
  const [patientCount, setPatientCount] = useState(0);
  const [deviceCount, setDeviceCount] = useState(0);
  const [sensorCount, setSensorCount] = useState(0);
  const [roleId, setRoleId] = useState("");

  const [selectedPatient, setSelectedPatient] = useState(null);

  const [showPatient, setShowPatient] = useState(false);
  const [showMeasurements, setShowMeasurements] = useState(false);
  const [companyRedirect, setCompanyRedirect] = useState(false);
  const [physicianRedirect, setPhysicianRedirect] = useState(false);
  const [patientRedirect, setPatientRedirect] = useState(false);
  const [deviceRedirect, setDeviceRedirect] = useState(false);
  const [sensorRedirect, setSensorRedirect] = useState(false);
  const [loadDevice, setLoadDevice] = useState(false);
  const [loadPatient, setLoadPatient] = useState(true);
  const [openPatientInfo, setOpenPatientInfo] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [redirecttoDeviceView, setRedirecttoDeviceView] = useState(false);
  const [redirecttoDeviceTwin, setRedirecttoDeviceTwin] = useState(false);
  const [companyCountHide, setCompanyCountHide] = useState(false);
  const [sensorCountHide, setSensorCountHide] = useState(false);
  const [physicianCountHide, setPhysicianCountHide] = useState(false);
  const [patientCountHide, setPatientCountHide] = useState(false);
  const [deviceCountHide, setDeviceCountHide] = useState(false);
  const [isPatientLogin, setIsPatientLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [patientInfo, setPatientInfo] = useState("false");
  const [patientTrend, setPatientTrend] = useState("false");

  const [company, setCompany] = useState([]);
  const [physicians, setPhysicians] = useState([]);
  const [patients, setPatients] = useState([]);
  const [device, setDevice] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [measurements, setMeasurements] = useState([]);
  const [assignedPhysician, setAssignedPhysician] = useState([]);
  const [patientprivilege, setPatientPrivilege] = useState([]);

  // const maskDeviceId = (randomDeviceId) => {
  //   if (!randomDeviceId) return "N/A"; // Handle case where randomDeviceId is undefined or null

  //   const prefix = randomDeviceId.substring(0, 4);
  //   const suffix = randomDeviceId.substring(randomDeviceId.length - 6);

  //   // Join prefix, maskedChars with "-", and suffix
  //   return `${prefix}xxxx-xxxx-xxxx-xxxx-${suffix}`;
  // }

  const [filterOptions, setFilterOptions] = useState([
    {
      value: "today",
      label: "Today",
    },
    {
      value: "week",
      label: "One Week",
    },
    {
      value: "month",
      label: "One Month",
    },
    {
      value: "all",
      label: "All",
    },
    // {
    //   value: 'date',
    //   label: 'Selected Dates',
    // },
    // {
    //   value: 'specificdate',
    //   label: 'Date',
    // },
  ]);

  const [durationFilterOptions, setDurationFilterOptions] = useState([
    {
      value: 1,
      label: "1 hr",
    },
    {
      value: 2,
      label: "2 hrs",
    },
    {
      value: 3,
      label: "3 hrs",
    },
    {
      value: 4,
      label: "4 hrs",
    },
    {
      value: 5,
      label: "5 hrs",
    },
    {
      value: 6,
      label: "6 hrs",
    },
    {
      value: 7,
      label: "7 hrs",
    },
  ]);

  const [selectedDateFilter, setSelectedDateFilter] = useState(
    filterOptions[0]
  );
  const [selectedParamFilter, setSelectedParamFilter] = useState([]);
  const [selectedDurationFilter, setSelectedDurationFilter] = useState(
    durationFilterOptions[0]
  );

  const [paramName, setParamName] = useState("");
  const [pidforChart, setPidForChart] = useState("");
  const [pnameforChart, setPnameForChart] = useState("");
  const [noMeasureText, setNoMeasureText] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCompany, setSelectedCompany] = useState(
    secureLocalStorage.getItem("compId") || ""
  );

  const [openDateFilter, setOpenDateFilter] = useState(null);
  const [openParamFilter, setOpenParamFilter] = useState(null);
  const [openDurationFilter, setOpenDurationFilter] = useState(null);

  const sliderRef = useRef(null);
  const theme = useTheme();
  const chartRefreshInterval = useRef(null);

  const SkeletonCard = () => (
    <Card
      sx={{
        maxWidth: 200,
        minHeight: 250,
        boxShadow: 5,
        marginLeft: 1.5,
        marginRight: 3,
      }}
    >
      <CardHeader
        title={
          <Skeleton
            animation="wave"
            height={8}
            width="70%"
            sx={{ bgcolor: "grey.400" }}
          />
        }
        sx={{ pb: 1 }} // Reduce padding at the bottom of the header
      />
      <Skeleton
        animation="wave"
        variant="rectangular"
        height={80} // Reduced height for the image placeholder
        sx={{ mx: 2, bgcolor: "grey.400" }}
      />
      <CardContent sx={{ py: 1 }}>
        <Skeleton
          animation="wave"
          height={8}
          width="60%"
          sx={{ bgcolor: "grey.400" }}
        />
        <Skeleton
          animation="wave"
          height={8}
          width="40%"
          sx={{ mt: 1, bgcolor: "grey.400" }}
        />
      </CardContent>
      <CardActions sx={{ pt: 0 }}>
        <Skeleton
          animation="wave"
          height={25}
          width={50}
          sx={{ bgcolor: "grey.400" }}
        />
        <Skeleton
          animation="wave"
          height={25}
          width={80}
          sx={{ ml: 1, bgcolor: "grey.400" }}
        />
      </CardActions>
    </Card>
  );

  const loadingCards = Array.from({ length: 2 }, (_, index) => (
    <SkeletonCard key={index} />
  ));

  {
    /* -----------------------Slider Settings----------------------------------------------------------------------------------- */
  }

  const settings = {
    // sets custom arrow components
    prevArrow: <MaterialArrow direction="prev" />,
    nextArrow: <MaterialArrow direction="next" />,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // const refreshData = (companyId) => {
  //   getPatient(companyId);
  //   getPhysician(companyId);
  //   getDevice(companyId);
  // };

  // const handleCompanySelect = (selectedCompany) => {
  //   setSelectedCompany(selectedCompany);
  //   refreshData(selectedCompany); // Call the refreshData function to update the data
  // };

  // useEffect(() => {
  //   refreshData(selectedCompany);
  // }, [selectedCompany]);

  useEffect(() => {
    var compid = "";
    var compname = "";
    var physid = "";
    var physname = "";

    const roleId = secureLocalStorage.getItem("roleId");
    setRoleId(roleId);
    const userId = secureLocalStorage.getItem("tenantMailId");

    getRoleBasedMenu(userId, roleId);

    switch (roleId) {
      case "3":
        console.log("tenant login");
        if (secureLocalStorage.getItem("compName") === null) {
          setCompanyCountHide(true);
        } else {
          setCompanyCountHide(false);
        }
        if (secureLocalStorage.getItem("physName") === null) {
          setPhysicianCountHide(true);
        } else {
          setPhysicianCountHide(false);
        }
        setSensorCountHide(false);
        setPatientCountHide(true);
        setIsPatientLogin(false);
        break;

      case "4":
        console.log("company login");
        if (secureLocalStorage.getItem("compName") === null) {
          setCompanyCountHide(true);
        } else {
          setCompanyCountHide(false);
        }
        if (secureLocalStorage.getItem("physName") === null) {
          setPhysicianCountHide(true);
        } else {
          setPhysicianCountHide(false);
        }
        setSensorCountHide(true);
        setPatientCountHide(false);
        setIsPatientLogin(false);
        break;

      case "6":
        console.log("physician login");
        if (secureLocalStorage.getItem("compName") === null) {
          setCompanyCountHide(true);
        } else {
          setCompanyCountHide(false);
        }
        if (secureLocalStorage.getItem("physName") === null) {
          setPhysicianCountHide(true);
        } else {
          setPhysicianCountHide(false);
        }
        setDeviceCountHide(true);
        setSensorCountHide(true);
        setPatientCountHide(false);
        setIsPatientLogin(false);
        break;

      case "8":
        console.log("patient login");
        if (secureLocalStorage.getItem("compName") === null) {
          setCompanyCountHide(true);
        } else {
          setCompanyCountHide(false);
        }
        if (secureLocalStorage.getItem("physName") === null) {
          setPhysicianCountHide(true);
        } else {
          setPhysicianCountHide(false);
        }
        setDeviceCountHide(true);
        setSensorCountHide(true);
        setPatientCountHide(true);
        setIsPatientLogin(true);
        break;

      default:
        break;
    }

    if (secureLocalStorage.getItem("compId") === null) {
      compid = "0";
      compname = "All";
      secureLocalStorage.setItem("compId", compid);
      secureLocalStorage.setItem("compName", compname);
    }
    if (secureLocalStorage.getItem("physId") === null) {
      physid = "0";
      physname = "All";
      secureLocalStorage.setItem("physId", physid);
      secureLocalStorage.setItem("physName", physname);
    }
    if (secureLocalStorage.getItem("compId") !== undefined) {
      compid = secureLocalStorage.getItem("compId");
    } else {
      compid = "0";
    }
    if (secureLocalStorage.getItem("compName") !== undefined) {
      compname = secureLocalStorage.getItem("compName");
    } else {
      compname = "All";
    }
    // ----------------------------------------------------------------------

    if (
      secureLocalStorage.getItem("physId") !== undefined &&
      secureLocalStorage.getItem("physId") !== null
    ) {
      physid = secureLocalStorage.getItem("physId");
    } else {
      physid = "0";
    }
    if (secureLocalStorage.getItem("physName") !== undefined) {
      compname = secureLocalStorage.getItem("physName");
    } else {
      physname = "All";
    }
    secureLocalStorage.setItem("physId", physid);
    secureLocalStorage.setItem("physName", physname);

    getPatient(physid, compid);
    getCompany();
    getPhysician();
    getDevice();
    getSensors();

    return () => {
      clearInterval(chartRefreshInterval.current);
    };
  }, []);

  const getRoleBasedMenu = (user, roleId) => {
    ApiCalls.getRoleBasedMenu(user, roleId).then(function (response) {
      console.log(response.data);
      const menuArray = response.data;

      const hasManageCompany = menuArray.some(
        (item) => item.url === "/managecustomer"
      );
      const hasManagePhysician = menuArray.some(
        (item) => item.url === "/managetherapist"
      );

      if (!hasManageCompany) {
        setCompanyCountHide(true);
      } else {
        setCompanyCountHide(false);
      }

      if (!hasManagePhysician) {
        setPhysicianCountHide(true);
      } else {
        setPhysicianCountHide(false);
      }
    });
  };

  const canvasRef = useRef(null);
  const chartRef = useRef(null);

  {
    /* -----------------------Company Details Listing API----------------------------------------------------------------------------------- */
  }

  const getCompany = () => {
    var tenanitid = secureLocalStorage.getItem("UUID");
    var compcount = 0;
    ApiCalls.getdetails(
      "organizations/0/tenants/" + tenanitid + "/getCompanyNames"
    ).then((result) => {
      if (result.data.companies != undefined) {
        for (var i = 0; i < result.data.companies.length; i++) {
          if (result.data.companies[i].companyuuid !== "0") {
            compcount += 1;
          }
        }
        if (result.data.companies !== undefined) {
          setCompanyCount(compcount);
          const options = result.data.companies.map((d) => ({
            value: d.companyuuid,
            label: d.name,
          }));
          setCompany(options);
        } else {
          setCompanyCount(0);
          const options = {
            value: "0",
            label: "None",
          };
          setCompany(options);
        }
      }
    });
  };

  {
    /* -----------------------Physician Details Listing API----------------------------------------------------------------------------------- */
  }

  const getPhysician = () => {
    var uuid = secureLocalStorage.getItem("UUID");
    var rolename = secureLocalStorage.getItem("rolename");
    var companyId = "";
    var tenantId = "";
    if (rolename === "Tenant") {
      tenantId = uuid;
      companyId = secureLocalStorage.getItem("compId");
    }
    if (rolename === "Company") {
      tenantId = secureLocalStorage.getItem("tenantId");
      companyId = uuid;
    }
    var physiciancount = 0;
    ApiCalls.getdetails(
      "organizations/0/tenants/" +
      tenantId +
      "/companies/" +
      companyId +
      "/getphysiciannames"
    )
      .then((result) => {
        for (var i = 0; i < result.data.physicians.length; i++) {
          if (result.data.physicians[i].physicianuuid !== "0") {
            physiciancount += 1;
          }
        }
        if (result.data.physicians !== undefined) {
          setPhysicianCount(physiciancount);
          const options = result.data.physicians.map(
            (d) => (
              {
                value: "0",
                label: "All",
              },
              {
                value: d.physicianuuid,
                label:
                  d.lastName === "All" ? "All" : d.firstName + " " + d.lastName,
              }
            )
          );
          setPhysicians(options);
        } else {
          setPatientCount(0);
        }
      })
      .catch((e) => {
        setPhysicianCount(0);
      });
  };
  {
    /* -----------------------Patient Details Listing API----------------------------------------------------------------------------------- */
  }

  const getPatient = () => {
    
    var companyId = secureLocalStorage.getItem("compId");
    var rolename = secureLocalStorage.getItem("rolename");
    var tenantId = secureLocalStorage.getItem("tenantId");
    var physicianId = "0";
    if (rolename === "Tenant") {
      tenantId = secureLocalStorage.getItem("UUID");
      companyId = secureLocalStorage.getItem("compId");
      physicianId = "0";
    }
    if (rolename === "Company") {
      tenantId = secureLocalStorage.getItem("tenantId");
      companyId = secureLocalStorage.getItem("UUID");
      physicianId = "0";
    }
    if (rolename === "Physician") {
      tenantId = secureLocalStorage.getItem("tenantId");
      companyId = secureLocalStorage.getItem("companyId");
      physicianId = secureLocalStorage.getItem("UUID");
    }

    var roleId = secureLocalStorage.getItem("roleId");

    ApiCalls.getdetails(
      "roleId/" + roleId + "/getpatientprivilegedetails"
    ).then((response) => {
      console.log(response.data);
      if (response.data.patientprivilege != undefined) {
        const patientInfo = response.data.patientprivilege[0].patientInfo;
        const patientTrend = response.data.patientprivilege[0].patientTrend;
        setPatientPrivilege(response.data.patientprivilege);
        console.log("PatientInfo: " + patientInfo);
        console.log("patientTrend: " + patientTrend);
        setPatientInfo(patientInfo);
        setPatientTrend(patientTrend);

        // Check patientInfo before making the second API call
        if (patientInfo === "true") {
          // Proceed with the second API call
          ApiCalls.getdetails(
            "organizations/0/tenants/" +
            tenantId +
            "/companies/" +
            companyId +
            "/physicians/" +
            physicianId +
            "/patients?"
          )
            .then((result) => {
              setLoadPatient(false); // Loading is complete, regardless of whether data is present or not

              if (
                result.data.patient !== undefined &&
                result.data.patient.length > 0
              ) {
                setPatients(result.data.patient);
                setShowPatient(true);
                setPatientCount(result.data.patient.length);
              } else {
                setPatients([]); // No data available
                setShowPatient(false);
                setPatientCount(0);
              }
            })
            .catch((e) => {
              setPatients([]);
              setPatientCount(0);
              setShowPatient(false);
              setLoadPatient(false);
            });
        } else {
          // Set the message if patientInfo is false
          console.log("No permission to view patient details");
          setPatients([]);
          setPatientCount(0);
          setShowPatient(false);
          setLoadPatient(false);
        }
      } else {
        setPatientPrivilege([]);
      }
    });
  };

  {
    /* -----------------------Device Details Listing API----------------------------------------------------------------------------------- */
  }

  const getDevice = () => {
    setLoadDevice(true);
    var tenantId = secureLocalStorage.getItem("tenantId");
    var companyId = secureLocalStorage.getItem("compId");
    var physicianId = "";
    var rolename = secureLocalStorage.getItem("rolename");
    if (rolename === "Tenant") {
      tenantId = secureLocalStorage.getItem("UUID");
      companyId = "0";
      physicianId = "0";
    } else if (rolename === "Company") {
      tenantId = secureLocalStorage.getItem("tenantId");
      companyId = secureLocalStorage.getItem("UUID");
      physicianId = "0";
    } else if (rolename === "Physician") {
      tenantId = secureLocalStorage.getItem("tenantId");
      companyId = secureLocalStorage.getItem("compId");
      physicianId = secureLocalStorage.getItem("UUID");
    }
    ApiCalls.getdetails(
      "PhysicianId/" + tenantId + "/company/" + companyId + "/showdevice"
    )
      .then((result) => {
        if (result.data.device !== undefined) {
          // Array to store promises for each device API call
          const devicePromises = [];

          result.data.device.forEach((device) => {
            // Extract device ID
            const deviceId = device.randomDeviceId;

            // Make API call with device ID
            const promise = ApiCalls.getdetails(
              "device/" + deviceId + "/getPatientAdmittedStatus"
            )
              .then((response) => {
                // Check if patient array exists and has elements
                if (response.data.patient && response.data.patient.length > 0) {
                  // If patient array exists and has elements, set device status as patient name
                  device.patientAdmittedStatus =
                    response.data.patient[0].firstName +
                    " " +
                    response.data.patient[0].lastName;
                } else {
                  // If patient array is undefined or empty, set device status as NotInUse
                  device.patientAdmittedStatus = "None";
                }
              })
              .catch((error) => {
                console.error(
                  "Error fetching patient admitted status for device:",
                  deviceId,
                  error
                );
              });

            // Add promise to array
            devicePromises.push(promise);
          });

          // Wait for all promises to resolve
          Promise.all(devicePromises)
            .then(() => {
              // All device API calls completed
              // Update device state, device count, and loading status
              setDevice(result.data.device);
              setLoadDevice(false);
              setDeviceCount(result.data.device.length);
            })
            .catch((error) => {
              console.error(
                "Error fetching patient admitted statuses for devices:",
                error
              );
              // Update device state, device count, and loading status
              setDevice([]);
              setLoadDevice(false);
              setDeviceCount(0);
            });
        } else {
          // Handle case where no devices are returned
          setDevice([]);
          setLoadDevice(false);
          setDeviceCount(0);
        }
      })
      .catch((error) => {
        // Handle error
        console.error("Error fetching device details:", error);
        // Update device state, device count, and loading status
        setDevice([]);
        setLoadDevice(false);
        setDeviceCount(0);
      });
  };

  {
    /* -----------------------Device Details Listing API----------------------------------------------------------------------------------- */
  }

  const getSensors = () => {
    var tenantId = secureLocalStorage.getItem("tenantId");
    ApiCalls.getdetails("tenantid/" + tenantId + "/listallsensors")
      .then((response) => {
        console.log(response.data);
        if (response.data.sensor != undefined) {
          setSensorCount(response.data.sensor.length);
          setLoading(false);
        } else {
          setSensorCount(0);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setSensorCount(0);
        setLoading(false);
      });
  };

  {
    /* -----------------------Handle Click Patient to view Chart & Data----------------------------------------------------------------------------------- */
  }

  const handleClickPatient = (patient) => {
    setSelectedDateFilter(filterOptions[0]);
    setSelectedParamFilter([]);
    setPidForChart(patient.patientuuid);
    var patientId = patient.patientuuid;
    var patientName = patient.patientName;

    if (patientId !== "" && patientId !== null) {
      setShowMeasurements(true);
    } else {
      setShowMeasurements(false);
    }

    getParameterName(patientId);
    var patientName = patient.firstName + " " + patient.lastName;
    setPnameForChart(patientName);
    secureLocalStorage.setItem("ChartPId", patientId);
    setNoMeasureText("");
    loadMeasurements(patientId);

    const interval = setInterval(() => {
      loadMeasurements(patientId);
      chartMeasurements(
        patientId,
        selectedParamFilter.label,
        selectedDateFilter.value,
        selectedDurationFilter.value
      );
    }, 5 * 60 * 1000); // 5 minutes in milliseconds
  };

  {
    /* -----------------------Parameter Details Listing API----------------------------------------------------------------------------------- */
  }

  const getParameterName = (patientId) => {
    ApiCalls.getdetails(
      "patientId/" + patientId + "/getmeasurementparameter"
    ).then((res) => {
      console.log(res.data);
      if (res.data.measure !== undefined) {
        setParamName(res.data.measure[0].paramName);
        const options = res.data.measure.map((d) => ({
          label: d.paramName,
          value: d.paramid,
        }));
        var dateFilter = "today";
        var durationFilter = 1;
        chartMeasurements(
          patientId,
          res.data.measure[0].paramName,
          dateFilter,
          durationFilter
        );
        setParameters(options);
        setSelectedParamFilter(options[0]);
      }
    });
  };

  {
    /* -----------------------Measurement Details Listing API----------------------------------------------------------------------------------- */
  }

  const loadMeasurements = (patientId) => {
    setLoading(true);
    var parameterName = "all";
    ApiCalls.getdetails(
      "patient/" +
      patientId +
      "/parameter/" +
      parameterName +
      "/getmeasurementdetails"
    )
      .then((res) => {
        if (res.data.measurement !== undefined) {
          setMeasurements(res.data.measurement);
          setNoMeasureText("");
          setShowMeasurements(true);
          setLoading(false);
        } else {
          setMeasurements([]);
          setShowMeasurements(false);
          setLoading(false);
          setNoMeasureText("No Measurements For");
          setPidForChart("");
        }
      })
      .catch((error) => {
        setMeasurements([]);
        setShowMeasurements(false);
        setLoading(false);
      });
  };

  {
    /* -----------------------Chart Data Listing API----------------------------------------------------------------------------------- */
  }

  const chartMeasurements = async (
    patientId,
    parameterName,
    dateFilter,
    durationFilter
  ) => {
    const canvas = canvasRef.current;

    if (!canvas) {
      return;
    }

    let labelData = [];
    let chartData = [];

    try {
      const res = await ApiCalls.getdetails(
        `patient/${patientId}/parameter/${parameterName}/${dateFilter}/getmeasurementwithfilter/0/0/${durationFilter}`
      );

      if (!res.data.measurement) {
        chartData = [];
      } else {
        for (const dataObj of res.data.measurement) {
          // labelData.push(moment.utc(Date.parse(dataObj.dateOfReading)).format('MMM-DD hh:mm A'));
          var dateToday = new Date(dataObj.dateOfReading)
            .toISOString()
            .replace(/(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2}).*/, "$1T$2");

          var date = new Date(dataObj.dateOfReading)
            .toISOString()
            .replace(
              /(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2}\.\d+).*/,
              "$1T$2Z"
            );
          console.log(date);
          labelData.push(dateFilter === "today" ? dateToday : date);
          // labelData.push(moment.utc(dataObj.measTimeStamp, 'ddd MMM DD HH:mm:ss UTC YYYY').format(dateFilter === 'today' ? 'hh:mm:ss A' : 'MMM-DD hh:mm:ss A'));
          chartData.push(dataObj.readingValues);
        }
      }
    } catch (err) {
      console.error(err);
      chartData = [];
    }

    if (chartRef.current) {
      chartRef.current.data.labels =
        labelData.length > 0 ? labelData : ["No data available"];
      chartRef.current.data.datasets =
        chartData.length > 0
          ? [
            {
              label: parameterName + " () Reading Level",
              data: chartData,
              borderColor: "#6ff9ff", // Brighter/high contrast version of #40e0d0
              // backgroundColor: 'RGBA(118, 176, 241, 0.5)',
              // fill: true,
              borderWidth: 1.5,
              tension: 0.5,
              pointStyle: "circle", // Set the point style to 'circle'
              pointRadius: 0, // Set the point radius to 0 to hide the points
            },
          ]
          : [
            {
              label: parameterName + " () Reading Level",
              data: [0],
              borderColor: "#6ff9ff", // Brighter/high contrast version of #40e0d0
              // backgroundColor: 'RGBA(118, 176, 241, 0.5)',
              // fill: true,
              borderWidth: 1.5,
              tension: 0.5,
            },
          ];

      chartRef.current.update();
    } else {
      chartRef.current = new Chart(canvas, {
        type: "line",
        data: {
          labels: labelData.length > 0 ? labelData : ["No data available"],
          datasets:
            chartData.length > 0
              ? [
                {
                  label: parameterName + " () Reading Level",
                  data: chartData,
                  borderColor: "#6ff9ff", // Brighter/high contrast version of #40e0d0
                  // backgroundColor: 'RGBA(118, 176, 241, 0.5)',
                  // fill: true,
                  borderWidth: 1.5,
                  tension: 0.5,
                  pointStyle: "circle", // Set the point style to 'circle'
                  pointRadius: 0, // Set the point radius to 0 to hide the points
                },
              ]
              : [
                {
                  label: parameterName + " () Reading Level",
                  data: [0],
                  borderColor: "#6ff9ff", // Brighter/high contrast version of #40e0d0
                  // backgroundColor: 'RGBA(118, 176, 241, 0.5)',
                  // fill: true,
                  borderWidth: 1.5,
                  tension: 0.5,
                },
              ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    }

    // Clear any existing interval before starting a new one
    if (chartRefreshInterval.current) {
      clearInterval(chartRefreshInterval.current);
    }

    chartRefreshInterval.current = setInterval(async () => {
      const currentTime = moment().format("HH:mm:ss");
      try {
        await chartMeasurements(
          patientId,
          parameterName,
          dateFilter,
          durationFilter
        );
        console.log(`after 5 mins... Current Time: ${currentTime}`);
      } catch (err) {
        console.error(err);
      }
    }, 5 * 60 * 1000); // 5 minutes in milliseconds
  };

  const handleOpenDurationFilters = (e) => {
    setOpenDurationFilter(e.currentTarget);
  };

  const handleCloseDurationFilters = () => {
    setOpenDurationFilter(null);
  };

  const handleDurationFilterChange = (filter) => {
    setSelectedDurationFilter(filter);
    chartMeasurements(
      pidforChart,
      selectedParamFilter.label,
      selectedDateFilter.value,
      filter.value
    );
    handleCloseDurationFilters();
  };

  const handleOpenDateFilters = (e) => {
    setOpenDateFilter(e.currentTarget);
  };

  const handleCloseDateFilters = () => {
    setOpenDateFilter(null);
  };

  const handleDateFilterChange = (filter) => {
    setSelectedDateFilter(filter);
    chartMeasurements(
      pidforChart,
      selectedParamFilter.label,
      filter.value,
      selectedDurationFilter.value
    );
    handleCloseDateFilters();
  };

  const handleOpenParamFilters = (e) => {
    setOpenParamFilter(e.currentTarget);
  };

  const handleCloseParamFilters = () => {
    setOpenParamFilter(null);
  };

  const handleParamFilterChange = (filter) => {
    setSelectedParamFilter(filter);
    chartMeasurements(
      pidforChart,
      filter.label,
      selectedDateFilter.value,
      selectedDurationFilter.value
    );
    handleCloseParamFilters();
  };

  const handleClickCompanyCount = () => {
    setCompanyRedirect(true);
  };
  const handleClickPhysicianCount = () => {
    setPhysicianRedirect(true);
  };

  const handleClickPatientCount = () => {
    setPatientRedirect(true);
  };

  const handleClickDeviceCount = () => {
    setDeviceRedirect(true);
  };

  const handleClickSensorCount = () => {
    setSensorRedirect(true);
  };

  const filteredPatients = patients.filter((patient) => {
    const firstName = patient.firstName ? patient.firstName.toLowerCase() : "";
    const lastName = patient.lastName ? patient.lastName.toLowerCase() : "";
    const searchTermLower = searchTerm.toLowerCase();
    return (
      firstName.includes(searchTermLower) || lastName.includes(searchTermLower)
    );
  });

  const handleOpenPatientInfo = (patient) => {
    setSelectedPatient(patient);
    loadAssignedPhysician(patient.patientuuid);
    setOpenPatientInfo(true);
  };

  const loadAssignedPhysician = (patientId) => {
    ApiCalls.getdetails("patient/" + patientId + "/getPhysician").then(
      (response) => {
        if (response.data.patient !== undefined) {
          setAssignedPhysician(response.data.patient);
        }
      }
    );
  };

  const handleClosePatientInfo = () => {
    setSelectedPatient(null);
    setOpenPatientInfo(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (companyRedirect) {
    return <Redirect to="/managecustomer" />;
  } else if (physicianRedirect) {
    return <Redirect to="/managetherapist" />;
  } else if (patientRedirect) {
    return <Redirect to="/managepatient" />;
  } else if (deviceRedirect && !isPatientLogin) {
    return <Redirect to="/managedevice" />;
  } else if (sensorRedirect) {
    return <Redirect to="/managesensor" />;
  }

  if (redirecttoDeviceView) {
    return <Redirect to={"/deviceview"} />;
  }

  if (redirecttoDeviceTwin) {
    return <Redirect to={"/devicetwin"} />;
  }

  const handleMouseDown = (e) => {
    e.preventDefault();
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return `${text.slice(0, maxLength)}...`;
  };

  return (
    <div style={{ display: "flex" }}>
      <Preview />
      <Container maxWidth="xl" sx={{ marginTop: 10 }}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          {secureLocalStorage.getItem("LoginUserName") == null
            ? "Welcome "
            : "Welcome " + secureLocalStorage.getItem("LoginUserName")}
        </Typography>

        <Grid container spacing={3}>
          <Grid
            item
            xs={6}
            sm={3}
            md={3}
            sx={{ display: companyCountHide ? "none" : "block" }}
          >
            <CountCard
              title={"Total " + secureLocalStorage.getItem("CompanyName")}
              total={companyCount}
              color="primary"
              icon={"uis:hospital"}
              onClick={handleClickCompanyCount}
            />
          </Grid>

          <Grid
            item
            xs={6}
            sm={3}
            md={3}
            sx={{ display: physicianCountHide ? "none" : "block" }}
          >
            <CountCard
              title={"Total " + secureLocalStorage.getItem("PhysicianName")}
              total={physicianCount}
              color="primary"
              icon={"fontisto:doctor"}
              onClick={handleClickPhysicianCount}
            />
          </Grid>

          <Grid
            item
            xs={6}
            sm={3}
            md={3}
            sx={{
              display:
                patientCountHide || patientInfo === "false" ? "none" : "block",
            }}
          >
            <CountCard
              title={"Total " + secureLocalStorage.getItem("PatientName")}
              total={patientCount}
              color="primary"
              icon={"material-symbols:recent-patient-rounded"}
              onClick={handleClickPatientCount}
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            md={3}
            sx={{ display: deviceCountHide ? "none" : "block" }}
          >
            <CountCard
              title={"Total " + secureLocalStorage.getItem("DeviceName")}
              total={deviceCount}
              color="primary"
              icon={"tabler:devices-cog"}
              onClick={handleClickDeviceCount}
            />
          </Grid>
          <Grid
            item
            xs={6}
            sm={3}
            md={3}
            style={{ display: sensorCountHide ? "none" : "block" }}
          >
            <CountCard
              title="Total Sensor"
              total={sensorCount}
              color="primary"
              icon={"flat-color-icons:electrical-sensor"}
              onClick={handleClickSensorCount}
            />
          </Grid>

          {/* -----------------------Patient Listing----------------------------------------------------------------------------------- */}

          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            hidden={
              patientCountHide || isPatientLogin || patientInfo === "false"
            }
          >
            <Card>
              <CardHeader
                title={secureLocalStorage.getItem("PatientName") + " List"}
                action={
                  <TextField
                    fullWidth
                    color="primary"
                    hidden={!showPatient}
                    label={
                      "Search " + secureLocalStorage.getItem("PatientName")
                    }
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                }
              />
              <CardContent>
                <Typography
                  sx={{ mb: 1.5 }}
                  color="text.secondary"
                  hidden={!showPatient || patientTrend === "false"}
                >
                  * Click the {secureLocalStorage.getItem("PatientName")} to
                  view the measurement details
                </Typography>
                <center>
                  <p style={{ fontWeight: "bold", fontSize: 11, color: "red" }}>
                    {noMeasureText === ""
                      ? " "
                      : noMeasureText + " " + pnameforChart}{" "}
                  </p>
                </center>

                <StyledCard
                  dir="ltr"
                  sx={{
                    ".slick-list": {
                      padding: 0,
                      margin: 0,
                    },
                    ".slick-track": {
                      display: "flex",
                      marginLeft: "-4px", // Adjust to add space between arrow and cards
                    },
                    ".slick-slide": {
                      marginRight: "4px",
                      marginLeft: "-1px", // Add marginLeft to all slides including the first one
                      flex: "0 0 auto", // Ensure the slides don't grow to fill space
                    },
                  }}
                >
                  <Slider ref={sliderRef} {...settings}>
                    {loadPatient ? (
                      loadingCards
                    ) : showPatient ? (
                      filteredPatients.length > 0 ? (
                        filteredPatients.map((patient) => (
                          <Card
                            sx={{
                              maxWidth: 220,
                              minHeight: 280,
                              border:
                                pidforChart == patient.patientuuid ? 3 : "none",
                              borderColor: "#76B0F1",
                              borderRadius: 2,
                              boxShadow: 5,
                              mx: 3,
                            }}
                            key={patient.patientuuid}
                          >
                            <CardHeader
                              titleTypographyProps={{ variant: "subtitle2" }}
                              title={`${patient.firstName
                                  ? patient.firstName.charAt(0).toUpperCase() +
                                  patient.firstName.slice(1)
                                  : ""
                                }
     ${patient.lastName
                                  ? patient.lastName.charAt(0).toUpperCase() + patient.lastName.slice(1)
                                  : ""
                                }`}
                            />

                            <CardMedia
                              component="img"
                              height="155"
                              width="155"
                              image={
                                patient.image == undefined ||
                                  patient.image == "null"
                                  ? UserImg
                                  : patient.image
                              }
                              alt={patient.firstName + " " + patient.lastName}
                              onClick={() =>
                                patientTrend === "true" &&
                                handleClickPatient(patient)
                              }
                              sx={{ objectFit: "contain", cursor: "pointer" }}
                            />
                            <CardActions disableSpacing>
                              <Tooltip
                                title={
                                  secureLocalStorage.getItem("PatientName") +
                                  " info"
                                }
                                onClick={() => handleOpenPatientInfo(patient)}
                                onMouseDown={handleMouseDown}
                              >
                                <IconButton aria-label="info">
                                  <InfoRounded color="primary" />
                                </IconButton>
                              </Tooltip>
                            </CardActions>
                          </Card>
                        ))
                      ) : (
                        <Typography variant="body1" align="center">
                          No {secureLocalStorage.getItem("PatientName")} found
                        </Typography>
                      )
                    ) : (
                      <Typography variant="body1" align="center">
                        No {secureLocalStorage.getItem("PatientName")} found
                      </Typography>
                    )}
                  </Slider>
                </StyledCard>
              </CardContent>
            </Card>
            <Dialog open={openPatientInfo} onClose={handleClosePatientInfo}>
              {selectedPatient &&
                openPatientInfo && ( // Check if selectedPatient is not null and dialog is open
                  <Card
                    sx={{
                      minWidth: 400,
                      backgroundColor: "#F4F6F8",
                    }}
                  >
                    <CardHeader
                      avatar={
                        <Avatar
                          alt={`${selectedPatient?.firstName} ${selectedPatient?.lastName}`}
                          src={`${selectedPatient?.image}`}
                          sx={{ width: 120, height: 120 }}
                        />
                      }
                      action={
                        <IconButton
                          color="primary"
                          onClick={handleClosePatientInfo}
                          onMouseDown={handleMouseDown}
                        >
                          <Close />
                        </IconButton>
                      }
                    />
                    <CardContent>
                      <Box sx={{ mt: 2, mb: 3 }}>
                        <Typography variant="h5" sx={{ color: "text.primary" }}>
                          {`${selectedPatient?.firstName} ${selectedPatient?.lastName}`}
                        </Typography>

                        <Typography
                          variant="subtitle2"
                          sx={{ color: "text.secondary" }}
                        >
                          {`${selectedPatient?.email}`}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            mb: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "text.secondary" }}
                          >
                            Gender
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "text.primary" }}
                          >
                            {`${selectedPatient?.gender}`}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            mb: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "text.secondary" }}
                          >
                            Phone
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "text.primary" }}
                          >
                            {`${selectedPatient?.phone}`}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            mb: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "text.secondary" }}
                          >
                            Location
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            sx={{ color: "text.primary" }}
                          >
                            {selectedPatient.address &&
                              Object.keys(selectedPatient.address).length > 0 ? (
                              <>
                                {selectedPatient.address.city &&
                                  selectedPatient.address.state &&
                                  selectedPatient.address.country ? (
                                  ` ${selectedPatient.address.state}, ${selectedPatient.address.country}`
                                ) : (
                                  <>
                                    {selectedPatient.address.city &&
                                      selectedPatient.address.state ? (
                                      `${selectedPatient.address.city}, ${selectedPatient.address.state}`
                                    ) : (
                                      <>
                                        {selectedPatient.address.city || ""}
                                        {selectedPatient.address.city &&
                                          selectedPatient.address.state &&
                                          selectedPatient.address.country
                                          ? ", "
                                          : ""}
                                        {selectedPatient.address.state || ""}
                                        {selectedPatient.address.state &&
                                          selectedPatient.address.country
                                          ? ", "
                                          : ""}
                                        {selectedPatient.address.country || ""}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              "Not Provided"
                            )}
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{
                            mb: 2,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                          }}
                        ></Box>
                      </Box>
                    </CardContent>
                    <CardActions>
                      <Button
                        onClick={handleClosePatientInfo}
                        onMouseDown={handleMouseDown}
                      >
                        Close
                      </Button>
                    </CardActions>
                  </Card>
                )}
            </Dialog>
          </Grid>

          {/* -----------------------Measurement Graph----------------------------------------------------------------------------------- */}

          <Grid item xs={12} md={12} lg={12} hidden={!showMeasurements}>
            <Card sx={{ height: 450 }}>
              <CardHeader
                title="Graphical Display"
                action={
                  <>
                    <Tooltip title="Filter Parameter">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={handleOpenParamFilters}
                        endIcon={<Iconify icon="material-symbols:filter-alt" />}
                      >
                        {selectedParamFilter.label}
                      </Button>
                    </Tooltip>
                    <Tooltip title="Filter Date">
                      <Button
                        className="ml-2"
                        color="primary"
                        variant="contained"
                        onClick={handleOpenDateFilters}
                        endIcon={
                          <Iconify icon="material-symbols:filter-list-rounded" />
                        }
                      >
                        {selectedDateFilter.label}
                      </Button>
                    </Tooltip>
                    <Tooltip title="Select Duration">
                      <Button
                        hidden={selectedDateFilter.value !== "today"}
                        className="ml-2 mr-2"
                        color="primary"
                        variant="contained"
                        onClick={handleOpenDurationFilters}
                        endIcon={<Iconify icon="ph:timer-fill" />}
                      >
                        {selectedDurationFilter.label}
                      </Button>
                    </Tooltip>
                  </>
                }
              />
              <CardContent>
                <Box
                  sx={{ p: 3, pb: 1, height: 350, borderRadius: "10px" }}
                  dir="ltr"
                  style={{ backgroundColor: "#27293d" }}
                >
                  <canvas ref={canvasRef} />
                </Box>
              </CardContent>
            </Card>
          </Grid>

          <Popover
            open={Boolean(openDurationFilter)}
            anchorEl={openDurationFilter}
            onClose={handleCloseDurationFilters}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            PaperProps={{
              sx: {
                p: 1,
                mt: 1.5,
                ml: 0.75,
                width: 180,
                "& .MuiMenuItem-root": {
                  px: 1,
                  typography: "body2",
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <Stack spacing={0.75}>
              {durationFilterOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  selected={option.value === selectedDurationFilter.value}
                  onClick={() => handleDurationFilterChange(option)}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Stack>
          </Popover>

          <Popover
            open={Boolean(openDateFilter)}
            anchorEl={openDateFilter}
            onClose={handleCloseDateFilters}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            PaperProps={{
              sx: {
                p: 1,
                mt: 1.5,
                ml: 0.75,
                width: 180,
                "& .MuiMenuItem-root": {
                  px: 1,
                  typography: "body2",
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <Stack spacing={0.75}>
              {filterOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  selected={option.value === selectedDateFilter.value}
                  onClick={() => handleDateFilterChange(option)}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Stack>
          </Popover>

          <Popover
            open={Boolean(openParamFilter)}
            anchorEl={openParamFilter}
            onClose={handleCloseParamFilters}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            PaperProps={{
              sx: {
                p: 1,
                mt: 1.5,
                ml: 0.75,
                width: "auto", // Change from fixed width to auto
                maxWidth: "300px", // Set a maximum width
                "& .MuiMenuItem-root": {
                  px: 1,
                  typography: "body2",
                  borderRadius: 0.75,
                  whiteSpace: "normal", // Allow text to wrap
                  wordBreak: "break-word", // Break long words if necessary
                },
              },
            }}
          >
            <Stack spacing={0.75}>
              {parameters.map((option) => (
                <MenuItem
                  key={option.value}
                  selected={option.value === selectedParamFilter.value}
                  onClick={() => handleParamFilterChange(option)}
                  sx={{
                    minHeight: "auto", // Allow MenuItem to adjust its height
                    py: 1, // Add some vertical padding
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Stack>
          </Popover>

          {/* -----------------------Measurement List----------------------------------------------------------------------------------- */}

          <Grid item xs={12} md={12} lg={12} hidden={!showMeasurements}>
            <MeasurementList
              title="Measurement List"
              measurements={measurements}
              setMeasurements={setMeasurements}
              patientId={pidforChart}
              loading={loading}
              setLoading={setLoading}
              onRefresh={chartMeasurements} // Pass chartMeasurements function as a prop
              selectedDurationFilterDash={selectedDurationFilter}
              selectedDateFilterDash={selectedDateFilter}
              selectedParamFilterDash={selectedParamFilter}
            />
          </Grid>

          {/* -----------------------Device List----------------------------------------------------------------------------------- */}
          <Grid item xs={12} md={12} lg={12} hidden={deviceCountHide}>
            <Card sx={{ height: 450 }}>
              <CardHeader
                title={secureLocalStorage.getItem("DeviceName") + " List"}
              />
              <CardContent>
                <StyledCard
                  dir="ltr"
                  sx={{
                    ".slick-list": {
                      padding: 0,
                      margin: 0,
                    },
                    ".slick-track": {
                      display: "flex",
                      marginLeft: "-4px", // Adjust to add space between arrow and cards
                    },
                    ".slick-slide": {
                      marginRight: "4px",
                      marginLeft: "-1px", // Add marginLeft to all slides including the first one
                      flex: "0 0 auto", // Ensure the slides don't grow to fill space
                    },
                  }}
                >
                  <Slider ref={sliderRef} {...settings}>
                    {loadDevice ? (
                      loadingCards
                    ) : device.length > 0 ? (
                      device.map((devices) => (
                        <Card
                          sx={{
                            maxWidth: 250,
                            minHeight: 320,
                            boxShadow: 5,
                            marginLeft: 1.5,
                            marginRight: 3,
                          }}
                          key={devices.deviceuuid}
                        >
                          <CardHeader
                            titleTypographyProps={{ variant: "subtitle2" }}
                            title={devices.devicename}
                          />
                          <CardMedia
                            component="img"
                            height="100"
                            width="100"
                            image={
                              devices.deviceimageName == undefined ||
                                devices.deviceimageName === "null"
                                ? DeviceImg
                                : devices.deviceimageName
                            }
                            alt={devices.devicename}
                            sx={{ objectFit: "contain" }}
                          />
                          <CardContent>
                            <Typography
                              gutterBottom
                              variant="body2"
                              component="div"
                            >
                              UUID : <b>{devices.randomDeviceId}</b>
                            </Typography>
                            <Typography
                              sx={{
                                display:
                                  secureLocalStorage.getItem("roleId") ===
                                    "4" ||
                                    secureLocalStorage.getItem("roleId") == 4 ||
                                    secureLocalStorage.getItem("roleId") ===
                                    "6" ||
                                    secureLocalStorage.getItem("roleId") == 6
                                    ? "block"
                                    : "none",
                              }}
                              gutterBottom
                              variant="body2"
                              component="div"
                            >
                              Assigned To :{" "}
                              <b>{devices.patientAdmittedStatus}</b>
                            </Typography>
                          </CardContent>
                          <CardActions>
                            <Tooltip
                              title={
                                secureLocalStorage.getItem("DeviceName") +
                                " info"
                              }
                              onMouseDown={(e) => e.preventDefault()}
                            >
                              <Button
                                onClick={() => {
                                  setRedirecttoDeviceView(true);
                                  secureLocalStorage.setItem(
                                    "deviceIdtoView",
                                    JSON.stringify(devices)
                                  );
                                }}
                                size="small"
                              >
                                view
                              </Button>
                            </Tooltip>
                            <Tooltip
                              title={
                                secureLocalStorage.getItem("DeviceName") +
                                " Twin"
                              }
                              onMouseDown={(e) => e.preventDefault()}
                            >
                              <Button
                                onClick={() => {
                                  setRedirecttoDeviceTwin(true);
                                  secureLocalStorage.setItem(
                                    "deviceIdtoTwin",
                                    JSON.stringify(devices)
                                  );
                                }}
                                size="small"
                              >
                                {secureLocalStorage.getItem("DeviceName") +
                                  " Twin"}
                              </Button>
                            </Tooltip>
                          </CardActions>
                        </Card>
                      ))
                    ) : (
                      <Typography variant="body1" align="center">
                        No {secureLocalStorage.getItem("DeviceName")} found
                      </Typography>
                    )}
                  </Slider>
                </StyledCard>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <div
          style={{
            height: "50px",
          }}
        />
      </Container>
    </div>
  );
}
