import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// @mui-components
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  MenuItem,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { alpha } from "@mui/material/styles";

//services
import Iconify from "../../components/iconify";

//externals
import secureLocalStorage from "react-secure-storage";
import swal from "sweetalert";

// ----------------------------------------------------------------------

const roleId = secureLocalStorage.getItem("roleId");
const roleName = secureLocalStorage.getItem("rolename");
console.log(roleId, "***************************************", roleName);

// const MENU_OPTIONS = [
//   {
//     label: "Home",
//     icon: <Iconify icon={"eva:home-fill"} />,
//     url: "/dashboard",
//   },
//   {
//     label: "Profile",
//     icon: <Iconify icon={"eva:person-fill"} />,
//     url: "/profile",
//   },
//   roleName === "Tenant"
//     ? {
//         label: "Log History",
//         icon: <Iconify icon={"mdi:clipboard-text-history-outline"} />,
//         url: "/loghistory",
//       }
//     : null,

//   // roleId === "2" || roleId === "3"
//   //   ? {
//   //       label: "Edit Configuration",
//   //       icon: <Iconify icon={"eva:edit-fill"} />,
//   //       url: "/wizardedit",
//   //     }
//   //   : null,
// ].filter(Boolean); // Filter out null values if roleId is not 2 or 3

// ----------------------------------------------------------------------

export default function AccountPopover({ account }) {
  const [open, setOpen] = useState(null);
  const [signout, setSignout] = useState(false);
  const [menuOptions, setMenuOptions] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const roleId = secureLocalStorage.getItem("roleId");
    const roleName = secureLocalStorage.getItem("rolename");

    const options = [
      {
        label: "Home",
        icon: <Iconify icon={"eva:home-fill"} />,
        url: "/dashboard",
      },
      {
        label: "Profile",
        icon: <Iconify icon={"eva:person-fill"} />,
        url: "/profile",
      },
    ];

    if (roleId === 3 || roleId === "3" || roleName === "Tenant") {
      options.push({
        label: "Log History",
        icon: <Iconify icon={"mdi:clipboard-text-history-outline"} />,
        url: "/loghistory",
      });
    }

    setMenuOptions(options);
  }, []);

  // const handleDeleteParameter = () => {
  //     swal({
  //         title: "Confirm Logout",
  //         text: "Are you sure you want to logout!!",
  //         icon: "warning",
  //         buttons: true,
  //         dangerMode: true,
  //     })

  // }

  const handleMenuClick = (url) => {
    if (url === "/dashboard" && localStorage.getItem("roleId") === "8") {
      history.push("/mydashboard");
    } else {
      history.push(url);
    }
  };

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleSignOut = () => {
    // Show the confirmation dialog using swal
    swal({
      title: "Confirm Logout",
      text: "Are you sure you want to logout?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((confirmed) => {
      // If the user clicks "OK" in the confirmation dialog, navigate to the sign-out page
      if (confirmed) {
        setSignout(true);
        var myItem = secureLocalStorage.getItem("dbname");
        secureLocalStorage.clear();
        secureLocalStorage.setItem("dbname", myItem);
        secureLocalStorage.setItem("isSignedIn", false);
        history.push("/signout"); // Replace '/signout' with the actual path to your sign-out page
        history.replace("/");
      }
    });
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return `${text.slice(0, maxLength)}...`;
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        onMouseDown={(e) => e.preventDefault()}
        sx={{
          p: 0,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.image} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5, width: 180 }}>
          <Typography
            variant="subtitle2"
            sx={{
              wordWrap: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {(account.firstName === undefined ? "" : account.firstName) +
              " " +
              (account.lastName === undefined ? "" : account.lastName)}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: "text.secondary",
              wordWrap: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {account.userEmail}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Stack sx={{ p: 1 }}>
          {menuOptions.map((option) => (
            <MenuItem
              key={option.label}
              onClick={() => handleMenuClick(option.url)}
            >
              <ListItemIcon>{option.icon}</ListItemIcon>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={handleSignOut} sx={{ m: 1 }}>
          <ListItemIcon>
            <Iconify icon={"majesticons:logout"} />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}
